<template>
  <div>
    <div class="flex-container">
      <div class="card" @mouseover="mouseOverPanelsCount" @mouseleave="mouseOverNone">
        <p class="card-title">{{ this.$t("simulator.results.cards.modules.title") }}</p>
        <div class="card-value" v-bind:class="{ blur: dialog }"><span class="card-value-number">{{ results.summary.modules }}</span></div>
      </div>
      <div class="card" @mouseover="mouseOverYearGeneration" @mouseleave="mouseOverNone">
        <p class="card-title">{{ this.$t("simulator.results.cards.generation.title") }}</p>
        <div class="card-value" v-bind:class="{ blur: dialog }"><span class="card-value-number">{{ results.tmy.total_gen_energy | formatEnergykWh }}</span><span class="card-value-unit"> kWh</span></div>
      </div>
      <div class="card" @mouseover="mouseOverTotalCost" @mouseleave="mouseOverNone">
        <p class="card-title">{{ this.$t("simulator.results.cards.cost.title") }}</p>
        <div class="card-value" v-bind:class="{ blur: dialog }"><span class="card-value-number">{{ results.accounting.total_costs | formatCurrency }}</span><span class="card-value-unit"> €</span></div>
      </div>
      <div class="card" @mouseover="mouseOverInstalledPower" @mouseleave="mouseOverNone">
        <p class="card-title">{{ this.$t("simulator.results.cards.power.title") }}</p>
        <div class="card-value" v-bind:class="{ blur: dialog }"><span class="card-value-number">{{ results.summary.stc | formatEnergykW }}</span><span class="card-value-unit"> kW</span></div>
      </div>
      <div class="card" @mouseover="mouseOverYearConsumption" @mouseleave="mouseOverNone">
        <p class="card-title">{{ this.$t("simulator.results.cards.consumption.title") }}</p>
        <div class="card-value" v-bind:class="{ blur: dialog }"><span class="card-value-number">{{ results.year_consumed_energy_sum | formatEnergykWh }}</span><span class="card-value-unit"> kWh</span></div>
      </div>
      <div class="card" @mouseover="mouseOverYearSavings" @mouseleave="mouseOverNone">
        <p class="card-title">{{ this.$t("simulator.results.cards.savings.title") }}</p>
        <div class="card-value" v-bind:class="{ blur: dialog }"><span class="card-value-number">{{ results.lifetime_yearly_savings["Expected"][0] | formatCurrency }}</span><span class="card-value-unit"> €</span></div>
      </div>
    </div>
    <div class="card-info">
      <v-icon color="primary">mdi-information-outline</v-icon>
      <p class="info-text">{{ infoText || defaultInfoText }}</p>
    </div>
    <div class="results-info-text">
      <div class="info-text" v-html="resultsConsumptionText"></div>
    </div>
    <div class="results-info-text">
      <div class="info-text" v-html="resultsInfoText"></div>
    </div>
  </div>
</template>

<script>

var numeral = require("numeral");
if (numeral.locales["es"] === undefined) {
  numeral.register("locale", "es", {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    abbreviations: {
      thousand: "k",
      million: "M",
    },
    currency: {
      symbol: "€",
    },
  });
}

// switch between locales
numeral.locale("es");

export default {
  props: {
    results: Object,
    dialog: Boolean,
  },
  data() {
    return {
      infoText: "",
      defaultInfoText: this.$t("simulator.results.cards.info_default"),
      resultsInfoText: this.$t("simulator.results.info"),
      resultsConsumptionText: this.$t("simulator.results.consumption"),
    }
  },
  methods: {
    mouseOverNone() {
      this.infoText = "";
    },
    mouseOverPanelsCount() {
      this.infoText = this.$t("simulator.results.cards.modules.info");
    },
    mouseOverInstalledPower() {
      this.infoText = this.$t("simulator.results.cards.power.info");
    },
    mouseOverTotalCost() {
      this.infoText = this.$t("simulator.results.cards.cost.info");
    },
    mouseOverYearGeneration() {
      this.infoText = this.$t("simulator.results.cards.generation.info");
    },
    mouseOverYearConsumption() {
      this.infoText = this.$t("simulator.results.cards.consumption.info");
    },
    mouseOverYearSavings() {
      this.infoText = this.$t("simulator.results.cards.savings.info");
    },
  },
  filters: {
    formatCurrency(value) {
      return numeral(value).format("0,0");
    },
    formatEnergykWh(value) {
      return numeral(value / 1000).format("0,0.");
    },
    formatEnergykW(value) {
      return numeral(value / 1000).format("0,0.0");
    },
  },
};
</script>

<style lang="scss" scoped>
div.flex-container {
  display: flex;
  /* Background fix as per https://stackoverflow.com/questions/66601200 */
  background: linear-gradient(black 0 0) center/calc(100% - 1px) 100% no-repeat;
  gap: 1px;
  flex-wrap: wrap;
  align-items: stretch;
}
div.card {
  background: white;
  text-align: center;
  padding-bottom: 10px;
}
div.card:hover {
  cursor: pointer;
}
div.card {
  flex: 30%;
  @media (max-width: 800px) {
    flex: 45%;
  }
  @media (max-width: 450px) {
    flex: 90%;
  }
}
p.card-title {
  margin-top: 16px;
  margin-bottom: 0px;
  color: black;
  font-weight: 500;
  font-size: 1.1em;
  font-family: "Roboto", Arial, sans-serif;
}
div.blur {
  filter: blur(20px);
}
div.card-value {
  color: var(--v-primary-base);
  font-weight: 500;
  font-family: "Roboto", Arial, sans-serif;
}
div.card-value span.card-value-number {
  font-size: 3.8em;
}
div.card-value span.card-value-unit {
  font-size: 2.5em;
}
div.card-info {
  margin-top: 20px;
  display: flex;
}
div.card-info p {
  margin: 10px 20px;
  font-weight: 400;
  font-size: .9em;
  font-family: "Roboto", Arial, sans-serif;
}
div.results-info {
  margin-top: 20px;
  display: flex;
}
div.results-info-text {
  margin: 20px 0px 0px 0px;
  font-weight: 400;
  font-size: .9em;
  font-family: "Roboto", Arial, sans-serif;
}
</style>
