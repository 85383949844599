export default {
  async fetchRequest(method, endpoint, data) {
    const options = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    if (data) {
      options.body = JSON.stringify(data)
    }

    const rawResponse = await fetch(endpoint, options)
    return rawResponse
  },
  async fetchRequestAPIKey(method, endpoint, data) {
    const options = {
      method,
      headers: {
        Accept: 'application/json',
        'x-api-key': process.env.VUE_APP_CLOUDFUNCTIONS_API_KEY,
        'Content-Type': 'application/json',
      },
    }
    if (data) {
      options.body = JSON.stringify(data)
    }

    const rawResponse = await fetch(endpoint, options)
    return rawResponse
  },
}

