import apiService from './api.service'

const CF_URL = process.env.VUE_APP_CLOUDFUNCTIONS_URL

export default {
  createLead(data) {
    return apiService.fetchRequestAPIKey('POST', `${CF_URL}/leads`, data)
  },
  updateLead(data, uuid) {
    return apiService.fetchRequestAPIKey('PUT', `${CF_URL}/leads/` + uuid, data)
  },
  getTimezone(data) {
    return apiService.fetchRequestAPIKey('GET', `${CF_URL}/timezone/` + data)
  }
}
