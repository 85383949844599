<template>
  <div>
    <v-stepper-step :complete="completed" :step="step">
      {{ title }}
    </v-stepper-step>
    <v-stepper-content :step="step">
      <v-row>
        <v-col class="d-flex justify-center" v-if="loading">
          <v-progress-circular
            :size="50"
            :width="5"
            :color="PRIMARYCOLOR"
            indeterminate
          >
          </v-progress-circular>
        </v-col>
        <v-col v-else-if="results.error">
          <v-alert color="red lighten-2" type="error" text>
            {{ $t("simulator.error") }}
          </v-alert></v-col
        >
        <v-col v-else-if="results"> <ReportForm :dialog="dialog" :results="results" /> </v-col>
      </v-row>
      <v-dialog v-if="dialog" v-model="dialog" max-width="900px" persistent no-click-animation>
        <CustomerDisplay
          :results="results"
          @next="dialog=false" />
      </v-dialog>
    </v-stepper-content>
  </div>
</template>

<script>
import ReportForm from "@/components/report/ReportForm";
import CustomerDisplay from "@/components/ui/CustomerDisplay";

export default {
  components: {
    ReportForm,
    CustomerDisplay,
  },
  props: {
    step: String,
    dataAvailable: Boolean,
  },
  created() {
    this.PRIMARYCOLOR = this.$vuetify.theme.themes.light.primary;
  },
  data() {
    return {
      completed: false,
      title: this.$t("simulator.results.title"),
      results: null,
      loading: true,
      dialog: false,
    };
  },
  watch: {
    dataAvailable: {
      handler: "reportData",
      inmediate: true,
    },
  },
  methods: {
    async reportData() {
      this.results = this.$store.getters["results"];
      this.loading = false;
      if (!this.results.error) {
        this.dialog = true;
        await this.$store.dispatch("createLead");
      }
    },
    previous() {
      this.completed = false;
      this.loading = true;
      this.$emit("previous");
    },
  },
};
</script>
