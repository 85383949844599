import Vue from 'vue'
import { gmapApi } from 'vue2-google-maps'

export default {
  async getAddress(location) {
    await Vue.$gmapApiPromiseLazy()
    var google = gmapApi()
    var geocoder = new google.maps.Geocoder()
    var address = {}
    geocoder.geocode({ location: location }, function(results) {
      const components = results[0].address_components
      address.postal_code = filterAddressComponents(components, 'postal_code')
      address.country = filterAddressComponents(components, 'country')
      address.administrative_area_level_1 = filterAddressComponents(
        components,
        'administrative_area_level_1',
      )
      address.administrative_area_level_2 = filterAddressComponents(
        components,
        'administrative_area_level_2',
      )
      address.locality = filterAddressComponents(components, 'locality')
      address.route = filterAddressComponents(components, 'route')
      address.street_number = filterAddressComponents(
        components,
        'street_number',
      )
    })
    return address
  },

}

function filterAddressComponents(arr, query) {
  var comp = arr.filter(el => el.types.includes(query))
  if (comp.length < 1) {
    return ''
  }
  return comp[0].short_name
}
