<template>
  <GmapAutocomplete
    :value="location"
    class="location-bar"
    :placeholder="placeholder"
    @place_changed="placeChanged"
  />
</template>

<script>
export default {
  props: {
    location: Object,
  },
  data() {
    return {
      placeholder: this.$t("components.location_bar.placeholder"),
    };
  },
  methods: {
    placeChanged(place) {
      var locationVar = { lat: 0, lng: 0 };
      if (place && place.geometry) {
        locationVar.lat = place.geometry.location.lat();
        locationVar.lng = place.geometry.location.lng();
        this.$emit("locationChanged", locationVar);
      } else {
        var coord = Array.from(
          place.name.matchAll(/[+-]?([0-9]*[.])?[0-9]+/g),
          (m) => m[0]
        );
        if (coord && coord.length == 2) {
          locationVar.lat = parseFloat(coord[0]);
          locationVar.lng = parseFloat(coord[1]);
          this.$emit("locationChanged", locationVar);
        }
      }
    },
  },
};
</script>

<style type="scss" scoped>
input.location-bar.pac-target-input {
  width: 100%;
  line-height: 20px;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid black;
  max-width: 600px;
  height: 38px;
  margin: 0;
  outline: none;
}
input.location-bar.pac-target-input::placeholder {
  font-size: 14px;
  color: black;
  opacity: 1;
}
</style>
