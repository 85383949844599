<template>
  <div>
    <v-stepper-step :complete="completed" :step="step">
      {{ title }}
    </v-stepper-step>
    <v-stepper-content class="custom-stepper-content" :step="step">
      <v-col>
        <v-row>
          <h1 class="consumption-title secondary--text">
            {{ this.$t("simulator.consumption.bill") }}
          </h1>
        </v-row>
        <v-row>
          <p class="mt-2 secondary--text">{{ this.$t("simulator.consumption.info") }}</p>
        </v-row>
        <v-row class="justify-space-between align-center mb-10">
          <TextFieldGroup
            :labels="labelsMonths"
            :values="valuesMonths"
            @valid="inputMonthChanged"
            @invalid="setInvalid"
          />
        </v-row>
        <v-row class="justify-end mt-12">
          <ButtonPrevious @click="previous" />
          <ButtonNext :disabled="!valid" @click="next" />
        </v-row>
      </v-col>
    </v-stepper-content>
  </div>
</template>

<script>
import ButtonNext from "@/components/ui/ButtonNext";
import ButtonPrevious from "@/components/ui/ButtonPrevious";
import TextFieldGroup from "@/components/ui/TextFieldGroup";

export default {
  components: {
    ButtonNext,
    ButtonPrevious,
    TextFieldGroup,
  },
  props: {
    step: String,
  },
  data() {
    return {
      valid: false,
      completed: false,
      title: this.$t("simulator.consumption.title"),
      labelsMonths: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  },
  computed: {
    filled() {
      this.location;
      return 1;
    },
    valuesMonths() {
      return this.$store.state.simulation.energyConsumption;
    },
  },
  methods: {
    inputMonthChanged(index, value) {
      const dataValue = value === "" ? null : parseFloat(value);
      this.$store.commit("setEnergyConsumptionMonthlyData", {
        index,
        value: isNaN(dataValue) ? value : dataValue,
      });
      this.valid = false;
      for (let i = 0; i < this.valuesMonths.length; i++) {
        if (this.valuesMonths[i] != null) {
          this.valid = true;
          break;
        }
      }
    },
    setInvalid() {
      this.valid = false;
    },
    next() {
      this.completed = true;
      this.$emit("next");
      this.$emit("simulate");
    },
    previous() {
      this.completed = false;
      this.inputMonthChanged([]);
      this.$emit("previous");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-stepper__content.custom-stepper-content {
  margin: 0;
}
.consumption-title {
  font-weight: 500;
  font-size: 1.3em;
  font-family: "Roboto", Arial, sans-serif;
}
</style>
