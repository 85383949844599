import Vue from 'vue'
import Vuex from 'vuex'

import cloudfunctionService from '@/services/cloudfunction.service'
import gmapsService from '@/services/gmaps.service'
import simulatorService from '@/services/simulator.service'

Vue.use(Vuex)

const DEFAULT_SIM = {
  location: null,
  surfaces: [],
  energyConsumption: Array(12).fill(null),
  timezone: '',
  address: {},
}

const DEFAULT_CUSTOMER = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  company_name: '',
}

export default new Vuex.Store({
  state: {
    simulation: DEFAULT_SIM,
    customer: DEFAULT_CUSTOMER,
    results: null,
    lid: '',
    partner: '',
  },
  getters: {
    simulation(state) {
      return state.simulation
    },
    customer(state) {
      return state.customer
    },
    results(state) {
      return state.results
    },
    lid(state) {
      return state.lid
    },
  },
  mutations: {
    setLocation(state, location) {
      state.simulation.location = location
    },
    setAddress(state, payload) {
      state.simulation.address = payload
    },
    setTimezone(state, payload) {
      state.simulation.timezone = payload
    },
    setSurfaces(state, payload) {
      state.simulation.surfaces = payload
    },
    setEnergyConsumptionMonthlyData(state, payload) {
      // New instance to force reactivity
      const newData = state.simulation.energyConsumption.slice(0)
      newData[payload.index] = payload.value
      state.simulation.energyConsumption = newData
    },
    setProfile(state, payload) {
      state.simulation.profile = payload
    },
    setReportJSON(state, payload) {
      state.results = payload
    },
    setCustomer(state, payload) {
      state.customer = payload
    },
    setPartner(state, payload) {
      state.partner = payload
    },
    setLeadId(state, payload) {
      state.lid = payload
    }
  },
  actions: {
    async runGeolocation({ getters, commit}) {
      const location = getters.simulation.location
      const data = JSON.stringify(location)
      const [address, timezone] = await Promise.all([
        gmapsService.getAddress(location),
        cloudfunctionService.getTimezone(data),
      ])
      const timezone_data = await timezone.json()
      commit('setAddress', address)
      commit('setTimezone', timezone_data)
    },
    async runSimulationReports({ getters, dispatch, commit }) {
      await dispatch("runGeolocation");
      const data = serializeData(getters)
      try {
        const response = await simulatorService.runSimulationReports(data)
        const output = await response.json()
        if (!response.ok) {
          commit('setReportJSON', { error: output })
        } else {
          commit('setReportJSON', output)
        }
      } catch {
        commit('setReportJSON', { error: 'no_response' })
      }
    },
    async createLead({ getters, commit }) {
      const data = {
        channel: 'B2B',
        results: getters.results,
        input: getters.simulation
      }
      try {
        const response = await cloudfunctionService.createLead(data);
        const output = await response.json()
        if (!response.ok) {
          commit('setLeadId', { error: output })
        } else {
          commit('setLeadId', output)
        }
      } catch {
        commit('setLeadId', { error: 'no_response' })
      }
    },
  }
})

function serializeData(state) {
  const sim = state.simulation
  return {
    consumption: {
      frequency: 'monthly',
      monthly: {
        units: 'kWh',
        data: sim.energyConsumption,
      },
      profile: 'REE - P3.0TD'
    },
    latitude: sim.location ? sim.location.lat : null,
    longitude: sim.location ? sim.location.lng : null,
    planes: sim.surfaces,
  }
}
