<template>
  <v-card>
    <v-system-bar height="60" color="primary" class="justify-center">
      <v-card-title class="white--text text-h5 font-weight-bold">Estás a un solo paso</v-card-title>
    </v-system-bar>
    <v-row no-gutters class="justify-center mt-4">
      <CustomerDataForm
        class="mt-4 mx-2"
        :customer="customer"
        @valid="getCustomerData"
        @invalid="setInvalid"
      />
    </v-row>
    <v-row no-gutters class="justify-center mt-4">
      <ButtonNext class="mt-0 mb-10" text="Ver resultados" :disabled="!valid" @click="next" :loading="loading"/>
    </v-row>
  </v-card>
</template>

<script>
import ButtonNext from "@/components/ui/ButtonNext";
import CustomerDataForm from "@/components/ui/CustomerDataForm";
import cloudfunctionService from '@/services/cloudfunction.service';
////////////////////////////
import { getApp } from "firebase/app"
import { getFunctions, httpsCallable } from "firebase/functions";
////////////////////////////

export default {
  components: {
    ButtonNext,
    CustomerDataForm,
  },
  ///////////////
  props: {
    results: Object,
  },
  /////////////
  data() {
    return {
      valid: false,
      title: this.$t("simulator.customer.title"),
      loading: false,
    };
  },
  computed: {
    customer() {
      return this.$store.state.customer;
    },
    partner() {
      return this.$store.state.partner;
    },
    lid() {
      return this.$store.state.lid;
    },
    ////////////////////////////////
    simulation() {
      return this.$store.state.simulation;
    },
    ////////////////////
  },
  methods: {
    getCustomerData(customer, partner) {
      this.valid = true
      this.$store.commit("setCustomer", customer);
      this.$store.commit("setPartner", partner);
    },
    setInvalid() {
      this.valid = false
    },
    async next() {
      this.loading = true;
      this.$emit("next");
      //sending email
      const functions = getFunctions(getApp(), 'europe-west1');
      const sendCustomerData = httpsCallable(functions,
        'total-sendCustomerData',
      )
      var email = {
        customer: this.customer,
        results: this.results,
        simulation: this.simulation,
      }
      await sendCustomerData(email)
      ////////////////////////////
      var data = {
        customer: this.customer,
        partner: this.partner,
      }
      await cloudfunctionService.updateLead(data, this.lid);
      this.loading = false;
    },
  },
};
</script>
