<template>
  <SimulatorForm />
</template>

<script>
import SimulatorForm from "./views/SimulatorForm";

export default {
  name: "App",
  components: {
    SimulatorForm,
  }
};
</script>
