<template>
  <v-app>
    <v-main>
      <v-stepper class="custom-stepper" v-model="selectedStep" vertical>
        <LocationStep step="1" @next="selectedStep = 2" />
        <SurfaceStep
          step="2"
          @next="fromSurfaceToConsumption"
          @previous="selectedStep = 1"
        />
        <ConsumptionStep
          step="3"
          @next="selectedStep = 4"
          @simulate="simulate"
          @previous="selectedStep = 2"
        />
        <ResultStep
          step="4"
          :dataAvailable="dataAvailable"
        />
      </v-stepper>
    </v-main>
  </v-app>
</template>

<script>
import LocationStep from "./components/LocationStep";
import SurfaceStep from "./components/SurfaceStep";
import ConsumptionStep from "./components/ConsumptionStep";
import ResultStep from "./components/ResultStep";
import simulatorService from '@/services/simulator.service';

export default {
  components: {
    SurfaceStep,
    ConsumptionStep,
    LocationStep,
    ResultStep,
  },
  data() {
    return {
      selectedStep: 1,
      dataAvailable: false,
    };
  },
  methods: {
    async simulate() {
      await this.$store.dispatch("runSimulationReports");
      this.dataAvailable = !this.dataAvailable;
    },
    async fromSurfaceToConsumption() {
      this.selectedStep = 3
      simulatorService.runPing();
    }
  },
};
</script>

<style lang="scss">
.v-main {
  margin: 2em 5%;
}
.v-stepper.custom-stepper {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.v-stepper__step {
  font-size: 1.5em;
  font-weight: 500;
  font-family: "Roboto", Arial, sans-serif;
}
.v-stepper__step__step {
  height: 1.0em;
  width: 1.0em;
  padding: .7em;
  font-size: 1.0em;
  font-weight: 700;
  font-family: "Roboto", Arial, sans-serif;
}
.v-stepper__step.v-stepper__step--active,
.v-stepper__step.v-stepper__step--complete {
  padding: 0.5em 0;
  border-bottom: 1px solid black;
}
.v-stepper__step.v-stepper__step--inactive {
  display: none;
}
.v-stepper__content {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 23px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
