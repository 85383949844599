<template>
  <v-form ref="form" v-model="valid" class="mx-12 my-2">
    <v-row no-gutters>
      <v-col>
        <v-text-field
          outlined
          :rules="[ruleEmpty]"
          :label="$t('simulator.customer.details.company_name')"
          :value="customer.company_name"
          @change="setCompanyName"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="5">
        <v-text-field
          outlined
          :label="$t('simulator.customer.details.name')"
          :value="customer.name"
          @change="setName"
        ></v-text-field>
      </v-col>
      <v-col class="pl-4">
        <v-text-field
          outlined
          :label="$t('simulator.customer.details.surname')"
          :value="customer.surname"
          @change="setSurname"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="5">
        <v-text-field
          class="secondary--text"
          outlined
          :rules="[rulePhone]"
          :label="$t('simulator.customer.details.phone')"
          :value="customer.phone"
          prefix="+34"
          @change="setPhone"
        ></v-text-field>
      </v-col>
      <v-col class="pl-4">
        <v-text-field
          outlined
          :rules="[ruleEmpty, ruleEmail]"
          :label="$t('simulator.customer.details.email')"
          :value="customer.email"
          @change="setEmail"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <p>{{ $t('simulator.customer.details.partner.text') }}</p>
        <v-text-field
          outlined
          :label="$t('simulator.customer.details.partner.label')"
          :value="partner"
          @change="setPartner"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-checkbox
        class="ml-3 mt-n2"
        :rules="[ruleTrue]"
        @change="setPrivacy"
      >
        <template v-slot:label>
          <div class="secondary--text">
            {{ text_check_1 }}
            &nbsp;
            <a
              class="black--text"
              target="_blank"
              href="https://www.totalenergies.es/es/pymes/politica-de-privacidad"
              @click.stop
            >
              {{ $t("simulator.customer.details.check_link") }}
            </a>
            {{ text_check_2 }}
          </div>
        </template></v-checkbox
      >
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    customer: Object,
  },
  data() {
    return {
      valid: true,
      valueVar: null,
      text_check_1: this.$t("simulator.customer.details.check_1"),
      text_check_2: this.$t("simulator.customer.details.check_2"),
      partner: '',
    };
  },
  watch: {
    valid: "validate",
  },
  methods: {
    setName(value) {
      this.customer.name = value;
      this.validate();
    },
    setSurname(value) {
      this.customer.surname = value;
      this.validate();
    },
    setCompanyName(value) {
      this.customer.company_name = value;
      this.validate();
    },
    setEmail(value) {
      this.customer.email = value;
      this.validate();
    },
    setPartner(value) {
      this.partner = value;
      this.validate();
    },
    setPhone(value) {
      this.customer.phone = value;
      this.validate();
    },
    setPrivacy() {
      this.validate();
    },
    ruleEmpty(value) {
      return !!value || this.$t("simulator.customer.details.rule_empty");
    },
    ruleTrue(value) {
      return value == true;
    },
    ruleEmail(value) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return (
        pattern.test(value) || this.$t("simulator.customer.details.rule_email")
      );
    },
    rulePhone(value) {
      const pattern = /^[0-9 ]+$/;
      return (
        pattern.test(value) ||
        !value ||
        this.$t("simulator.customer.details.rule_phone")
      );
    },
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$emit("valid", this.customer, this.partner);
      } else {
        this.$emit("invalid");
      }
    },
  },
};
</script>
<style scoped>
.v-text-field {
  border-radius: 10px;
}
</style>
