<template>
  <v-form ref="form" v-model="valid">
    <v-row class="mx-4 mt-2">
      <v-col v-for="(label, index) in labels" :key="index" cols="3" md="2">
        <v-text-field
          :rules="[ruleNumber]"
          :label="label"
          :value="values[index]"
          @change="setValue(index, $event)"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    labels: Array,
    values: Array,
  },
  data() {
    return {
      valid: true,
      indexVar: null,
      valueVar: null,
    };
  },
  watch: {
    valid: "validate",
  },
  methods: {
    ruleNumber(value) {
      const pattern = /^\d+\.?\d*$/;
      return pattern.test(value) || !value || "Número inválido";
    },
    setValue(index, value) {
      this.indexVar = index;
      this.valueVar = value;
      this.validate();
    },
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$emit("valid", this.indexVar, this.valueVar);
      } else {
        this.$emit("invalid");
      }
    },
  },
};
</script>
