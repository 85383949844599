<template>
  <div>
    <v-stepper-step :complete="completed" :step="step">
      {{ title }}
    </v-stepper-step>
    <v-stepper-content :step="step">
      <DrawingMap
        :location="location"
        :reset="resetFlag"
        @surfacesChanged="setSurfaces"
      />
      <v-row justify="end" no-gutters>
        <ButtonPrevious @click="previous" />
        <ButtonNext :disabled="!filled" @click="next" />
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import ButtonNext from "@/components/ui/ButtonNext";
import ButtonPrevious from "@/components/ui/ButtonPrevious";

const DrawingMap = () =>
  import(/* webpackChunkName: "DrawingMap" */ "@/components/gmaps/DrawingMap");

export default {
  components: {
    DrawingMap,
    ButtonNext,
    ButtonPrevious,
  },
  props: {
    step: String,
  },
  data() {
    return {
      completed: false,
      title: this.$t("simulator.surface.title"),
      reset_flag: false,
    };
  },
  computed: {
    location() {
      return this.$store.state.simulation.location;
    },
    surfaces() {
      return this.$store.state.simulation.surfaces;
    },
    filled() {
      return this.surfaces.length > 0;
    },
    resetFlag() {
      return this.reset_flag;
    },
  },
  methods: {
    async setSurfaces({ surfaces, centroid }) {
      this.$store.commit("setSurfaces", surfaces);
      this.$store.commit("setLocation", centroid);
    },
    next() {
      this.completed = true;
      this.$emit("next");
    },
    previous() {
      this.completed = false;
      this.reset_flag = !this.reset_flag;
      this.$emit("previous");
    },
  },
};
</script>

<style lang="scss" scoped>
.surface-title {
  font-weight: 400;
  font-size: 1em;
  font-family: "Roboto", Arial, sans-serif;
  letter-spacing: -0.005em;
}
</style>
