<template>
  <div>
    <v-stepper-step :complete="completed" :step="step">
      {{ title }}
    </v-stepper-step>
    <v-stepper-content :step="step">
      <v-row no-gutters>
        <v-col no-gutters cols="12" sm="6" md="4">
          <LocationBar :location="location" @locationChanged="setLocation" />
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import LocationBar from "@/components/gmaps/LocationBar";

export default {
  components: {
    LocationBar,
  },
  props: {
    step: String,
  },
  data() {
    return {
      completed: false,
      title: this.$t("simulator.location.title"),
    };
  },
  computed: {
    location() {
      return this.$store.state.simulation.location;
    },
  },
  methods: {
    setLocation(value) {
      this.$store.commit("setLocation", value);
      this.completed = true;
      this.$emit("next");
    },
  },
};
</script>
