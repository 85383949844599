import Vue from 'vue'
import App from './App.vue'
import store from './store'
import i18n from './i18n'

import './plugins/gmaps'
import vuetify from './plugins/vuetify'

import { initializeApp } from "firebase/app"

initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PRODUCT_ID,
})

Vue.config.productionTip = false

new Vue({
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
