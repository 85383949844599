import apiService from './api.service'

const BASE_URL = process.env.VUE_APP_SIMULATOR_URL

export default {
  runSimulationReports(data) {
    return apiService.fetchRequest('POST', `${BASE_URL}/simulate/ac4147c9-91a0-4a3d-b091-c626c9c09c0d`, data)
  },
  runPing() {
    return apiService.fetchRequest('GET', `${BASE_URL}/ping/ac4147c9-91a0-4a3d-b091-c626c9c09c0d`)
  },
}

