<template>
  <v-btn
    min-width="110"
    rounded
    outlined
    :color="PRIMARYCOLOR"
    @click="$emit('click')"
  >
    {{ $t("simulator.buttons.previous") }}
  </v-btn>
</template>

<script>
export default {
  created() {
    this.PRIMARYCOLOR = this.$vuetify.theme.themes.light.primary;
  },
};
</script>
