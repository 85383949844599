<template>
  <v-btn
    rounded
    depressed
    color="primary"
    min-width="110"
    class="white--text ml-3"
    :disabled="disabled"
    @click="$emit('click')"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    text: {
      default: 'Continuar',
      type: String,
    }
  },
};
</script>

